#lamp1 {
    position: fixed;
    left: 0;
    width: 200px;
    height: 200px;
    border-radius: 100px;
    animation: flicker 10s ease-in-out infinite alternate;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0);
}

@keyframes flicker {
    0% {
        box-shadow: inset 3px 3px 10px rgba(186, 229, 253, 1), inset 3px 3px 20px rgba(186, 229, 253, 1), inset 3px 3px 30px rgba(186, 229, 253, 0.7), inset 3px 3px 40px rgba(0, 0, 0, 0.5), inset 3px 3px 100px rgba(0, 0, 0, 0.5);
        transform: translateY(0);
    }

    50% {
        box-shadow: inset 3px 3px 10px rgba(186, 229, 253, 1), inset 3px 3px 20px rgba(186, 229, 253, 1), inset 3px 3px 30px rgba(186, 229, 253, 0.1), inset 3px 3px 40px rgba(0, 0, 0, 0.8), inset 3px 3px 100px rgba(0, 0, 0, 0.8);
        transform: translateY(8%);
    }

    100% {
        box-shadow: inset 3px 3px 10px rgba(186, 229, 253, 1), inset 3px 3px 20px rgba(186, 229, 253, 1), inset 3px 3px 30px rgba(186, 229, 253, 0.7), inset 3px 3px 40px rgba(0, 0, 0, 0.5), inset 3px 3px 100px rgba(0, 0, 0, 0.5);
        transform: translateY(0);
    }
}