.counter {
    height: 1.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    border: 1.5px solid black;
    overflow: hidden;
}

.counter__btnLeft {
    height: calc(1.5rem + 5px);
    width: 1.4rem;
    padding: 0 0 5px 0;
    border: none;
    border-right: 1.5px solid black;
    background-color: rgba(226, 226, 226, 1);
    color: black;
    font-size: 1.4rem;
    font-weight: 700;
    z-index: 2;
}

.counter__btnLeft:hover {
    background-color: rgb(196, 196, 196);
}

.counter__btnLeft.clickFade:hover{
    transition: 0.3s background-color ease-out;
}

.counter__btnLeft.clicked:hover {
    background-color: rgb(216, 216, 216);
    transition: none;
}

.counter__btnRight {
    height: calc(1.5rem + 1px);
    width: 1.4rem;
    padding: 0 0 1px 0;
    border: none;
    border-left: 1.5px solid black;
    background-color: rgba(226, 226, 226, 1);
    color: black;
    font-size: 1rem;
    font-weight: 700;
    z-index: 2;
}

.counter__btnRight:hover {
    background-color: rgb(196, 196, 196);
}

.counter__btnRight.clickFade:hover {
    transition: 0.3s background-color ease-out;
}

.counter__btnRight.clicked:hover {
    background-color: rgb(216, 216, 216);
    transition: none;
}

.counter__value {
    padding: 0 0.4rem;
}