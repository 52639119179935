.productImage {
    position: absolute;
    width: 50%;
    height: calc(100% - 6vh);
    margin: 3vh 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.productImage__border {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-right: 20px;
    margin-left: 15px;
    border-radius: 20px;
    box-shadow:
        0 0 0 4px white,
        0 0 0 5px lightgray;
}

.productImage__src {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}