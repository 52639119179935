#home {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

#home__content {
    width: calc(max(70vw, 380px));
    height: 90vh;
    margin-top: 5vh;
}

#home__navBar {
    position: relative;
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
}

#home__productVenue {
    display: flex;
    height: 2.5rem;
    width: 45%;
    margin: 0 20px 0 30px;
}

#venueDateAndTime{
    margin-right: 10px;
}

#venueLocation{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#home__logo {
    position: absolute;
    display: flex;
    justify-content: flex-end;
}

#logo {
    width: 55%;
    font-family: Bilal;
    font-size: 3em;
    color: gray;
    margin: 0;
}

#home__cart {
    height: 5vh;
}