.snowflake {
    position: absolute; // [1] Break the layout flow

    &:after {
        content: "\2744"; // [3] Give it some shape
    }
}

// Total number of snowflakes
$snowflakes: 100;

$colors: rgb(114, 220, 255), lightblue, rgb(216, 245, 255), rgb(234, 234, 234), gray;

// Randomize the animation and positioning for each snowflake

@for $i from 1 through $snowflakes {
    // Position of the snowflake on the y-axis
    $top: (random(50) + 50) * 1%;

    // Position of the snowflake on the x-axis
    $left: random(100) * 1%;

    // Animation delay for the flake
    $delay: random(20) - 1s;

    // Floating span for the flake
    $duration: random(6)+20s;

    // Size of the flake
    $size: 10+random(24);

    /* Snowflake ##{$i} */
    .snowflake:nth-of-type(#{$i}) {
        animation-name: snowflake-#{$i};
        animation-delay: $delay;

        // Play the animation for anything between 5-10 seconds
        animation-duration: $duration;
        animation-iteration-count: infinite;
        left: $left;
        top: -$top;

        color: nth($colors, 1 + random(length($colors) - 1));

        &:after {
            font-size: $size * 1px;
        }
    }

    // Animation for snowflake ##{$i}
    @keyframes snowflake-#{$i} {
        0% {
            transform: rotate(0deg);
            left: $left;
            top: -$top;
        }

        25% {
            left: $left + 5%;
        }

        50% {
            left: $left;
        }

        75% {
            left: $left + 10%;
            opacity: 1;
        }

        100% {
            transform: rotate(360deg);
            top: $top + 40%;
            opacity: 0;
        }
    }
}